// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import { useState } from "react"
import ChevronDownIcon from "https://cdn.jsdelivr.net/npm/@heroicons/react@2.2.0/24/outline/esm/ChevronDownIcon.js"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function MultiSelectDropDown({
    options,
    placeholder,
    value,
    allValuePlaceholder,
    onChange,
}: {
    options: string[]
    value: string[]
    placeholder: string
    allValuePlaceholder: string
    onChange: (value: string[]) => void
}) {
    const [isOpen, setIsOpen] = useState(false)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleOptionClick = (option) => {
        onChange(
            value.includes(option)
                ? value.filter((item) => item !== option)
                : [...value, option]
        )
    }

    return (
        <div
            style={{
                position: "relative",
                display: "inline-block",
                fontFamily: "Poppins",
            }}
        >
            <button
                onClick={toggleDropdown}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    gap: "0.5em",
                    paddingInline: "1em",
                    paddingBlock: "0.5em",
                    border: "2px solid #007ec6",
                    borderRadius: "0.75em",
                    backgroundColor: "white",
                    color: "#3c3c3c",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                    alignItems: "center",
                }}
            >
                {value.length === 0
                    ? placeholder
                    : options?.length === value.length
                      ? allValuePlaceholder
                      : value.join(" & ")}
                <ChevronDownIcon
                    style={{
                        width: "1em",
                        height: "1em",
                    }}
                />
            </button>

            {isOpen && (
                <ul
                    role="listbox"
                    aria-multiselectable="true"
                    style={{
                        listStyle: "none",
                        padding: 0,
                        margin: 0,
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        position: "absolute",
                        backgroundColor: "#fff",
                        zIndex: 1,
                        width: "fit-content",
                    }}
                >
                    {options?.map((option, index) => (
                        <li
                            key={index}
                            role="option"
                            aria-selected={value.includes(option)}
                            onClick={() => handleOptionClick(option)}
                            style={{
                                padding: "8px",
                                cursor: "pointer",
                                display: "relative",
                                paddingInlineStart: "2em",
                                backgroundColor: value.includes(option)
                                    ? "#eee"
                                    : "#fff",
                            }}
                        >
                            {value.includes(option) && (
                                <span
                                    style={{
                                        position: "absolute",
                                        left: "0.5em",
                                    }}
                                >
                                    ✓
                                </span>
                            )}
                            <span>{option}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
